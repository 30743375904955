<template>
  <div class="stepsdemo-content">
    <Card class="py-10">
      <template v-slot:title>
        <h2 class="text-center mx-auto w-full lg:w-3/4 xl:w-1/2">
          Información del juzgado
        </h2>
      </template>
      <template v-slot:content>
        <div class="p-fluid p-2 mx-auto w-full lg:w-3/4 xl:w-1/2 flex justify-center items-center gap-4">
          <div class="w-full">
            <div class="px-2 w-full mb-4 flex flex-col" style="width: 100% !important;">
              <AutoComplete
                class="w-full"
                inputClass="w-full"
                v-model="selectedJuzgadoCategoria"
                :suggestions="suggestionsCategoria"
                @complete="searchJuzgadoCategoria($event)"
                placeholder="Tipo de juzgado"
                field="nombre"
              />
              <MessageError :text="errors.categoriaId"/>
            </div>
            <div class="px-2 w-full flex flex-col">
              <dropdown
                placeholder="Seleccione numero de juzgado"
                class="w-full mb-4"
                :options="noJuzgados"
                option-label="nombre"
                option-value="nombre"
                v-model="model.code"
                :filter="true"
              />
            </div>
            <div class="px-2 w-full flex flex-col">
              <div class="mb-4">
                <AutoComplete
                  class="w-full"
                  inputClass="w-full"
                  v-model="selectedJuzgado"
                  :suggestions="suggestions"
                  @complete="searchJuzgado($event)"
                  @item-select="selectJuzgado($event)"
                  field="nombre"
                  placeholder="Nombre del juzgado"
                >
                  <template #item="{ item }">
                    <div>
                      <span>{{ item.fullName }}</span>
                    </div>
                  </template>
                </AutoComplete>
                <MessageError :text="errors.nombre"/>
              </div>
            </div>
            <div class="px-2 w-full flex flex-col">
              <div class="mb-4">
                <dropdown
                  placeholder="Seleccione departamento"
                  class="w-full"
                  :options="departamentos"
                  option-label="nomDpto"
                  option-value="codDpto"
                  v-model="model.departamentoId"
                  @change="getMpio"
                  :filter="true"
                />
                <MessageError :text="errors.departamentoId"/>
              </div>
              <div class="mb-4">
                <dropdown
                  class="w-full"
                  placeholder="Seleccione municipio"
                  :options="municipios"
                  option-label="nomMpio"
                  option-value="codMpio"
                  v-model="model.municipioId"
                  :filter="true"
                />
                <MessageError :text="errors.municipioId"/>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="w-full p-2 mx-auto lg:w-3/4 xl:w-1/2 flex justify-center items-center">
          <div class="flex w-full justify-center md:justify-between px-2">
            <Button label="Regresar" @click="prevPage()" icon="pi pi-angle-left"/>
            <Button label="Continuar" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { computed, onMounted, ref, watch } from 'vue'
import juzgadoCategoriaStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/juzgadoCategoria.store'
import storeLocalizacion from '@/apps/pharmasan/juridica/procesosJuridicos/store/localizacion.store'
import storeTutela from '@/apps/pharmasan/juridica/procesosJuridicos/store/tutela.store'
import JuzgadoService from '@/apps/pharmasan/juridica/procesosJuridicos/services/juzgado.service'
import JuzgadoCategoriaService from '../../../../../services/juzgadoCategoria.service'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import NoJuzgadosService from '@/apps/pharmasan/juridica/procesosJuridicos/services/noJuzgados.service'

export default {
  name: 'DatosAccionante',
  setup () {
    /** intancias **/
    const router = useRouter()
    const _JuzgadoService = new JuzgadoService()
    const _JuzgadoCategoriaService = new JuzgadoCategoriaService()
    const _NoJuzgadosService = new NoJuzgadosService()
    /** state **/
    const schema = yup.object({
      categoriaId: yup.number().label('').nullable(),
      nombre: yup.string().max(255).nullable().required('El campo es requerido').label(''),
      departamentoId: yup.string().nullable().required('El campo es requerido').label(''),
      municipioId: yup.string().nullable().required('El campo es requerido').label(''),
      code: yup.string().label('')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('categoriaId')
    useField('nombre')
    useField('departamentoId')
    useField('municipioId')
    useField('id')
    useField('code')
    /** data **/
    const suggestions = ref([])
    const suggestionsCategoria = ref([])
    const selectedJuzgado = ref(null)
    const selectedJuzgadoCategoria = ref(null)
    const categoria = ref({ nombre: null })
    /** computed **/
    const juzgadoCategorias = computed(() => juzgadoCategoriaStore.getters._juzgadoCategorias)
    const departamentos = computed(() => storeLocalizacion.getters._departamentos)
    const dataTutela = computed(() => {
      return storeTutela.getters._tutela
    })
    const noJuzgados = ref([])
    const municipios = computed(() => storeLocalizacion.getters._municipiosJuzgado)
    /** methodos **/
    const nextPage = handleSubmit((values) => {
      values.categoria = categoria.value
      storeTutela.commit('setJuzgado', values)
      router.push('detalle-tutela', { formData: { ...values }, pageIndex: 1 })
    })
    const prevPage = () => {
      router.push('datos-accionante', { pageIndex: 1 })
    }
    const getMpio = ({ value }, municipioId = null) => {
      if (!value) {
        storeLocalizacion.commit('setMunicipiosJuzgado', [])
      } else {
        storeLocalizacion.dispatch('getMunicipiosJuzgado', value).then(() => {
          model.municipioId = municipioId
        })
      }
    }
    const searchJuzgadoCategoria = ({ query }) => {
      _JuzgadoCategoriaService.search({ nombre: query }).then(({ data }) => {
        const result = data.reduce((resultado, objeto) => {
          if (!resultado.some(elem => elem.nombre === objeto.nombre)) {
            resultado.push(objeto)
          }
          return resultado
        }, [])
        suggestionsCategoria.value = result
      })
    }
    const searchJuzgado = ({ query }) => {
      _JuzgadoService.search({ nombre: query }).then(({ data }) => {
        suggestions.value = data
      })
    }
    const selectJuzgado = ({ value }) => {
      if (!value) return
      model.id = value.id
      model.categoriaId = value.categoriaId
      model.nombre = value.nombre
      model.departamentoId = value.departamentoId
      model.code = value.code
      getMpio({ value: value.departamentoId }, value.municipioId)
    }

    watch(selectedJuzgado, (val) => {
      if (typeof val === 'string') {
        model.nombre = val
      }
    })
    watch(selectedJuzgadoCategoria, (val) => {
      if (val.id) {
        model.categoriaId = val.id
      } else {
        categoria.value.nombre = val
        model.categoriaId = null
      }
    })
    /** hooks **/
    onMounted(() => {
      juzgadoCategoriaStore.dispatch('getJuzgadoCategorias')
      _NoJuzgadosService.paginate().then(({ data }) => {
        noJuzgados.value = data.rows
      })
      setTimeout(() => {
        model.categoriaId = dataTutela.value.juzgado.categoria.id
        selectedJuzgadoCategoria.value = {
          id: dataTutela.value.juzgado.categoria.id,
          nombre: dataTutela.value.juzgado.categoria.nombre
        }
        selectedJuzgado.value = {
          ...dataTutela.value.juzgado
        }
        model.nombre = dataTutela.value.juzgado.nombre
        model.departamentoId = dataTutela.value.juzgado.departamentoId
        model.municipioId = dataTutela.value.juzgado.municipioId
        getMpio({ value: dataTutela.value.juzgado.departamentoId }, dataTutela.value.juzgado.municipioId)
        model.code = dataTutela.value.juzgado.code
      }, 500)
    })
    return {
      juzgadoCategorias,
      errors,
      model,
      nextPage,
      getMpio,
      departamentos,
      noJuzgados,
      municipios,
      searchJuzgado,
      suggestions,
      selectJuzgado,
      selectedJuzgado,
      prevPage,
      searchJuzgadoCategoria,
      suggestionsCategoria,
      selectedJuzgadoCategoria,
      categoria,
      dataTutela
    }
  }
}
</script>
